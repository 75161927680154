/**
 * Directly forked from: https://github.com/mantinedev/mantine/blob/master/packages/@mantine/hooks/src/use-hotkeys/use-hotkeys.ts
 */

// ===========================================================================
// Internals
// ===========================================================================

/**
 * Internal type to determine the keyboard modifiers
 * (keys that are used alongside other keys).
 */
type KeyboardModifiers = {
  /** The "alt" key differs: On Windows (Alt), mac (⌥ or Option). */
  alt: boolean;
  /** The "ctrl" key is same across OS. */
  ctrl: boolean;
  /** The "meta" key differs: On Windows (🪟), mac (⌘). */
  meta: boolean;
  /** The "mod" key differs: On Windows (ctrl), mac (⌘). */
  mod: boolean;
  /** The "shift" key is same across OS. */
  shift: boolean;
};

/**
 * Internal type that represents a HotKey (a combination of a keyboard modifier + a key)
 */
type Hotkey = KeyboardModifiers & {
  key?: string;
};

/**
 * Internal type for a callback that checks if a HotKey matches a KeyboardEvent.
 */
type CheckHotkeyMatch = (event: KeyboardEvent) => boolean;

/** Internal function that parses a HotKey string into a HotKey object. */
function parseHotkey(params: { hotkey: string }): Hotkey {
  const { hotkey } = params;
  const keys = hotkey
    .toLowerCase()
    .split("+")
    .map((part) => part.trim());

  const modifiers: KeyboardModifiers = {
    alt: keys.includes("alt"),
    ctrl: keys.includes("ctrl"),
    meta: keys.includes("meta"),
    mod: keys.includes("mod"),
    shift: keys.includes("shift"),
  };

  const reservedKeys = ["alt", "ctrl", "meta", "shift", "mod"];

  const freeKey = keys.find((key) => !reservedKeys.includes(key));

  return {
    ...modifiers,
    key: freeKey,
  };
}

/** Internal function that checks if a HotKey exactly matches a KeyboardEvent. */
function isExactHotkey(params: {
  hotkey: Hotkey;
  event: KeyboardEvent;
}): boolean {
  const { hotkey, event } = params;
  const { alt, ctrl, meta, mod, shift, key } = hotkey;
  const { altKey, ctrlKey, metaKey, shiftKey, key: pressedKey } = event;

  if (alt !== altKey) {
    return false;
  }

  if (mod) {
    if (!ctrlKey && !metaKey) {
      return false;
    }
  } else {
    if (ctrl !== ctrlKey) {
      return false;
    }
    if (meta !== metaKey) {
      return false;
    }
  }
  if (shift !== shiftKey) {
    return false;
  }

  if (
    key &&
    (pressedKey.toLowerCase() === key.toLowerCase() ||
      event.code.replace("Key", "").toLowerCase() === key.toLowerCase())
  ) {
    return true;
  }

  return false;
}

/** Internal function that returns a function that can check if a HotKey matches a KeyboardEvent. */
function getHotkeyMatcher(hotkey: string): CheckHotkeyMatch {
  return (event) => isExactHotkey({ hotkey: parseHotkey({ hotkey }), event });
}

/** Internal type for options that you can add to a `HotKeyItem` */
type HotkeyItemOptions = {
  preventDefault?: boolean;
};

/**
 * You can also use this for hotkeys when currently focusing an input or textarea.
 *
 * By passing it in the `onKeyDown` prop of the input or textarea.
 *
 * @example
 * <input onKeyDown={getHotkeyHandler([['mod+Enter', () => console.log('Handle Submit!')]])} />
 *
 * @see https://mantine.dev/hooks/use-hotkeys/#targeting-elements
 */
export function getHotkeyHandler(hotkeys: HotkeyItem[]) {
  return (event: React.KeyboardEvent<HTMLElement> | KeyboardEvent) => {
    const _event = "nativeEvent" in event ? event.nativeEvent : event;
    hotkeys.forEach(([hotkey, handler, options = { preventDefault: true }]) => {
      if (getHotkeyMatcher(hotkey)(_event)) {
        if (options.preventDefault) {
          event.preventDefault();
        }

        handler(_event);
      }
    });
  };
}

// ===========================================================================
// use-hotkeys hook
// ===========================================================================
import { useEffect } from "react";

/**
 * The type to be passed inside the first arg of a `useHotkeys` hook.
 *
 * For instance, you can:
 * @example
 * import { HotkeyItem, useHotkeys } from '@mantine/hooks';
 *
 * const hotkeys: HotkeyItem[] = [
 *  [
 *    'mod+J',
 *    () => console.log('Toggle color scheme'),
 *    { preventDefault: false },
 *  ],
 *  ['ctrl+K', () => console.log('Trigger search')],
 *  ['alt+mod+shift+X', () => console.log('Rick roll')],
 * ];
 *
 * useHotkeys(hotkeys);
 */
export type HotkeyItem = [
  string,
  (event: KeyboardEvent) => void,
  HotkeyItemOptions?,
];

/**
 * Based on the `tagsToIgnore` passed.
 *
 * This returns true if event can be fired; false otherwise.
 */
function shouldFireEvent(params: {
  /** The event to fire. */
  event: KeyboardEvent;
  /** Element tags to ignore (e.g. "INPUT", "TEXTAREA") so it doesn't get called when focusing there. */
  tagsToIgnore: string[];
  triggerOnContentEditable?: boolean;
}) {
  const { event, tagsToIgnore, triggerOnContentEditable = false } = params;
  if (event.target instanceof HTMLElement) {
    if (triggerOnContentEditable) {
      return !tagsToIgnore.includes(event.target.tagName);
    }

    return (
      !event.target.isContentEditable &&
      !tagsToIgnore.includes(event.target.tagName)
    );
  }

  return true;
}

/**
 * A hook that handles listening to keyboard events or shortcuts
 * then executing a callback function.
 *
 * Tip: If you target Ctrl (Windows) or ⌘ (Mac), use 'mod' e.g. mod+S or mod+K.
 *
 * @example
 *   // Ignore hotkeys events only when focus is in input and textarea elements
 * useHotkeys(
 *  [['ctrl+K', () => console.log('Trigger search')]],
 *  ['INPUT', 'TEXTAREA']
 * );
 *
 * // Empty array – do not ignore hotkeys events on any element
 * useHotkeys([['ctrl+K', () => console.log('Trigger search')]], []);
 *
 * @see https://mantine.dev/hooks/use-hotkeys/
 */
export function useHotkeys(params: {
  /** List of hotkey items with their combinations and callbacks. */
  hotkeys: HotkeyItem[];
  /** Element tags to ignore (e.g. "INPUT", "TEXTAREA") so it doesn't get called when focusing there. */
  tagsToIgnore?: string[];
  /**
   * When true, hotkey will ONLY trigger when `contenteditable` attribute on HTML element is true.
   * @defaultValue `false`
   */
  triggerOnContentEditable?: boolean;
}) {
  const {
    hotkeys,
    tagsToIgnore = ["INPUT", "TEXTAREA", "SELECT"],
    triggerOnContentEditable = false,
  } = params;

  useEffect(() => {
    const keydownListener = (event: KeyboardEvent) => {
      hotkeys.forEach(
        ([hotkey, handler, options = { preventDefault: true }]) => {
          if (
            getHotkeyMatcher(hotkey)(event) &&
            shouldFireEvent({ event, tagsToIgnore, triggerOnContentEditable })
          ) {
            if (options.preventDefault) {
              event.preventDefault();
            }

            handler(event);
          }
        },
      );
    };

    document.documentElement.addEventListener("keydown", keydownListener);
    return () =>
      document.documentElement.removeEventListener("keydown", keydownListener);
  }, [hotkeys, tagsToIgnore, triggerOnContentEditable]);
}
