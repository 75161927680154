import { Icon } from "@/components/client/icon";
import { type ReactNode } from "react";

/** Props for the Empty component. */
type EmptyProps = {
  /** Label under the Empty icon. e.g. "No interactions found". */
  label?: ReactNode;
  /** Additional Class names for the container. */
  className?: string;
};

/**
 * Displays an empty state indicator with an icon and a label.
 *
 * @example
 *       📥
 *  No mails found
 */
export function Empty({ label, className }: EmptyProps) {
  return (
    <div className={`flex flex-col gap-y-2 items-center w-full ${className}`}>
      <Icon
        src="inbox-empty-with-shadow"
        className="!text-6xl !text-tpl-navy-light"
      />
      <div className="text-tpl-navy-light text-sm">{label}</div>
    </div>
  );
}
