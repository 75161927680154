/**
 * Just keep custom TipTap extensions in this file. You usually use this for
 * custom keyboard overrides and stuff.
 *
 * Tip 1: If you're trying to do something related to keyboards, it's useful to use this https://www.toptal.com/developers/keycode
 * Tip 2: Extensions don't seem to be REACTIVE. Don't try to pass "changing" states here.
 */

/**
 * Third-party libraries.
 */
import { Extension } from "@tiptap/core";

/**
 * A custom extension that:
 * - prevents "Enter" from creating a new paragraph.
 * - allows "Shift + Enter" to create a new paragraph (recreates "Enter").
 *
 * This is exactly the same as how Jira comments or Slack messages Rich Text Editor works.
 * You can then use `onKeyDown` to add some hotkeys for Enter using our own `use-hotkeys` hook internals.
 */
export const ShiftEnterHandlerExtension = Extension.create<{}>({
  name: "shift_enter_handler",

  addKeyboardShortcuts() {
    return {
      /**
       * Disable "Enter".
       */
      Enter: () => true,
      /**
       * Basically re-create what 'Enter' does.
       *
       * I was able to recreate by going into tiptap's source code.
       * - Base Key maps: https://github.com/ueberdosis/tiptap/blob/e8e4df38d5935f4bec5b8203446e0548fad57a77/packages/core/src/extensions/keymap.ts#L56C31-L61C7
       * - List Item extension key maps: https://github.com/ueberdosis/tiptap/blob/e8e4df38d5935f4bec5b8203446e0548fad57a77/packages/extension-list-item/src/list-item.ts#L57
       */
      "Shift-Enter": () => {
        /**
         * List Item Extension Keymaps:
         *
         * This conditionally runs when you're currently on a list item.
         * There's a longer version of this which is to check:
         * 1. on bulletList or orderedList
         * 2. Then checking if list item's paragraph is empty.
         *
         * But it's simpler to do this, then check if it "ran" so we can fallback
         * to paragraph splitting behavior.
         */
        const success = this.editor.commands.splitListItem("listItem");
        if (success) return true;

        /**
         * Base Extension Keymaps:
         */
        this.editor.commands.first(({ commands }) => [
          () => commands.newlineInCode(),
          () => commands.createParagraphNear(),
          () => commands.liftEmptyBlock(),
          () => commands.splitBlock(),
        ]);

        return true;
      },
    };
  },
});
