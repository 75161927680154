"use client";

/**
 * Third-party libraries.
 */
import { ExclamationCircleFilled, UserOutlined } from "@ant-design/icons";
import { Contact } from "@generated/type-graphql";
import { Avatar, Tooltip } from "antd";
import { FC, useMemo } from "react";

/**
 * Project components.
 */
import { CopyButton } from "@/components/client/button";

/** The only data required from the Contact model for the ContactProfile component. */
type ContactProfileData = Pick<
  Contact,
  "id" | "displayName" | "email" | "phoneNumbers" | "photoUrl" | "issues"
>;

/** Types for the ContactProfile component. */
type ContactProfileProps = {
  /** The id, displayName, email, phoneNumbers, and photoUrl of the contact. */
  data?: ContactProfileData;
  /** The flag to determine if the component should be visible. */
  visible: boolean;
  /** The class name for the container of the component. */
  className?: string;
};

/** Component for displaying the name, email, phoneNumbers, and photoUrl of the contact. */
const ContactProfile: FC<ContactProfileProps> = (props) => {
  const { data: contact, visible, className } = props;

  /** Primary phone number. */
  const primaryPhoneNumber = useMemo(() => {
    return contact?.phoneNumbers.find((phoneNumber) => phoneNumber.primary);
  }, [contact]);

  /** List of all secondary phone numbers. */
  const secondaryNumbers = useMemo(() => {
    return (
      contact?.phoneNumbers.filter((phoneNumber) => !phoneNumber.primary) || []
    );
  }, [contact]);

  if (!visible) {
    return null;
  }

  return (
    <div className="hover:bg-neutral-light-gray w-full animate-slide-left rounded-lg border border-[#E8EBF1] bg-white p-4">
      <div className="flex items-center gap-2">
        {contact?.photoUrl ? (
          // eslint-disable-next-line @next/next/no-img-element
          <img src={contact.photoUrl} alt="" className="h-8 w-8 rounded-full" />
        ) : (
          <Avatar icon={<UserOutlined />} />
        )}
        <h2 className="m-0">{contact?.displayName}</h2>
        {contact?.issues.length! > 0 && (
          <Tooltip
            placement="bottom"
            title={
              <div>
                <div className="font-bold">Issues:</div>
                {contact?.issues.map((issue) => (
                  <div key={issue.name + issue.message} className="py-1">
                    <div className="text-sm font-black">{issue.name}</div>
                    <div className="text-xs">{issue.message}</div>
                  </div>
                ))}
              </div>
            }
          >
            <ExclamationCircleFilled className="text-lg !text-semantic-red" />
          </Tooltip>
        )}
      </div>

      {contact?.email && (
        <div className="mt-2 flex items-center gap-2">
          <span className="text-sm">Email: </span>
          <span className="text-sm text-tpl-blue">{contact?.email}</span>
          <CopyButton value={contact?.email || ""} />
        </div>
      )}

      {primaryPhoneNumber && (
        <div className="mt-2 flex items-center gap-2">
          <span className="text-sm">Main: </span>
          <span className="text-sm text-tpl-blue">
            {primaryPhoneNumber.canonicalForm || primaryPhoneNumber.rawForm}
          </span>
          {primaryPhoneNumber.label && (
            <span className="text-sm text-tpl-navy-light">
              ({primaryPhoneNumber.label})
            </span>
          )}
          <CopyButton
            value={
              primaryPhoneNumber.canonicalForm || primaryPhoneNumber.rawForm
            }
          />
        </div>
      )}

      {secondaryNumbers.length > 0 && (
        <div className="mt-2 flex gap-2">
          <span className="text-sm">Secondary: </span>
          <div className="flex flex-col gap-2">
            {secondaryNumbers?.map((phoneNumber) => (
              <div
                key={phoneNumber.rawForm}
                className="flex items-center gap-2"
              >
                <span className="text-sm text-tpl-blue">
                  {phoneNumber.canonicalForm || phoneNumber.rawForm}
                </span>
                {phoneNumber?.label && (
                  <span className="text-sm text-tpl-navy-light">
                    ({phoneNumber?.label})
                  </span>
                )}
                <CopyButton
                  value={phoneNumber.canonicalForm || phoneNumber?.rawForm}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Uncomment to add "View Profile on Archus" */}
      {/* <div className="mt-2 flex items-center gap-1 border-t border-[#E8EBF1] pt-4">
        <a href="#" className="flex gap-2">
          <span>View Profile on Archus</span>
          <ImportOutlined rotate={90} />
        </a>
      </div> */}
    </div>
  );
};

export default ContactProfile;
